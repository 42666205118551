import { ShopifyData } from "../Query";

// ============================================================================================== //
// ======================================= ADD ITEM TO CART ===================================== //
// ============================================================================================== //

export const addItemToCart = async (cartId, product, greetingCard, rebuy) => {
  //   if (greetingCard !== undefined) {
  //     if (product.sellingPlanId) {
  //       const query = `mutation {
  //   cartLinesAdd(cartId: "${cartId}", lines: [{
  //     merchandiseId: "${product.merchandiseId}",
  //     quantity: ${product.quantity},
  //     sellingPlanId: "${product.sellingPlanId}"
  //   },{
  //     merchandiseId: "${greetingCard.merchandiseId}",
  //     quantity: ${greetingCard.quantity},
  //   }]) {
  //     cart {
  //       id
  //       checkoutUrl
  //       lines(first: 25) {
  //         nodes {
  //           id
  //           sellingPlanAllocation {
  //             sellingPlan {
  //               id
  //               name
  //             }
  //             priceAdjustments {
  //               price {
  //                 amount
  //               }
  //             }
  //           }
  //           merchandise {
  //             ... on ProductVariant {
  //               id
  //               title
  //               image {
  //                 url
  //               }
  //               priceV2 {
  //                 amount
  //               }
  //             }
  //           }
  //           quantity
  //         }
  //       }
  //       attributes {
  //         key
  //         value
  //       }
  //       estimatedCost {
  //         totalAmount {
  //           amount
  //           currencyCode
  //         }
  //         subtotalAmount {
  //           amount
  //           currencyCode
  //         }
  //         totalTaxAmount {
  //           amount
  //           currencyCode
  //         }
  //         totalDutyAmount {
  //           amount
  //           currencyCode
  //         }
  //       }
  //     }
  //     userErrors {
  //       code
  //       field
  //       message
  //     }
  //   }
  // }
  // `;

  //       const discountsQuery = `mutation {
  //         cartDiscountCodesUpdate(cartId: "${cartId}", discountCodes: ["GREETINGCARD"]) {
  //         cart {
  //         id
  //           checkoutUrl
  //         }
  //         userErrors {
  //           field
  //           message
  //         }
  //       }
  //     }`;

  //       const response = await ShopifyData(query);
  //       await ShopifyData(discountsQuery);
  //       return response.data.cartLinesAdd.cart;
  //     } else {
  //       const query = `mutation {
  //       cartLinesAdd(cartId: "${cartId}", lines: [{
  //         merchandiseId: "${product.merchandiseId}",
  //         quantity: ${product.quantity}
  //       },{
  //         merchandiseId: "${greetingCard.merchandiseId}",
  //         quantity: ${greetingCard.quantity},
  //       }]) {
  //         cart {
  //           id
  //           checkoutUrl
  //           lines(first: 25) {
  //             nodes {
  //               id
  //               sellingPlanAllocation {
  //                 sellingPlan {
  //                   id
  //                   name
  //                 }
  //                 priceAdjustments {
  //                   price {
  //                     amount
  //                   }
  //                 }
  //               }
  //               merchandise {
  //                 ... on ProductVariant {
  //                   id
  //                   title
  //                   image {
  //                     url
  //                   }
  //                   priceV2 {
  //                     amount
  //                   }
  //                 }
  //               }
  //               quantity
  //             }
  //           }
  //           attributes {
  //             key
  //             value
  //           }
  //           estimatedCost {
  //             totalAmount {
  //               amount
  //               currencyCode
  //             }
  //             subtotalAmount {
  //               amount
  //               currencyCode
  //             }
  //             totalTaxAmount {
  //               amount
  //               currencyCode
  //             }
  //             totalDutyAmount {
  //               amount
  //               currencyCode
  //             }
  //           }
  //         }
  //         userErrors {
  //           code
  //           field
  //           message
  //         }
  //       }
  //     }
  //     `;

  //       const discountsQuery = `mutation {
  //       cartDiscountCodesUpdate(cartId: "${cartId}", discountCodes: ["GREETINGCARD"]) {
  //         cart {
  //          id
  //           checkoutUrl
  //         }
  //         userErrors {
  //           field
  //           message
  //         }
  //       }
  //     }`;

  //       const response = await ShopifyData(query);
  //       await ShopifyData(discountsQuery);
  //       return response.data.cartLinesAdd.cart;
  //     }
  //   } else {
  if (product.sellingPlanId) {
    if (rebuy) {
      const query = `mutation {
    cartLinesAdd(cartId: "${cartId}", lines: [{
      merchandiseId: "${product.merchandiseId}",
      quantity: ${product.quantity},
      sellingPlanId: "${product.sellingPlanId}"
    }], 
    attributes: [{key: "_source", value: "Rebuy"}, {key: "_widget_id", value: "78696"}]) {
      cart {
        id
        checkoutUrl
        lines(first: 25) {
          nodes {
            id
            sellingPlanAllocation {
              sellingPlan {
                id
                name
              }
              priceAdjustments { 
                price {
                  amount
                }
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                priceV2 {
                  amount
                }
              }
            }
            quantity
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  `;

      const response = await ShopifyData(query);
      return response.data.cartLinesAdd.cart;
    } else {
      const query = `mutation {
        cartLinesAdd(cartId: "${cartId}", lines: [{
          merchandiseId: "${product.merchandiseId}",
          quantity: ${product.quantity},
          sellingPlanId: "${product.sellingPlanId}"
        }]) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

      const response = await ShopifyData(query);
      return response.data.cartLinesAdd.cart;
    }
  } else {
    if (rebuy) {
      const query = `mutation {
        cartLinesAdd(cartId: "${cartId}", lines: [{merchandiseId: "${product.merchandiseId}", quantity: ${product.quantity}, attributes: [{key: "_source", value: "Rebuy"}, {key: "_widget_id", value: "78696"}]}]) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

      const response = await ShopifyData(query);

      return response.data.cartLinesAdd.cart;
    } else {
      const query = `mutation {
        cartLinesAdd(cartId: "${cartId}", lines: [{
          merchandiseId: "${product.merchandiseId}",
          quantity: ${product.quantity}
        }]) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

      const response = await ShopifyData(query);
      return response.data.cartLinesAdd.cart;
    }
  }
  // }
};
