import { ShopifyData } from "../Query";

// ============================================================================================== //
// ================================ CREATE CART WITHOUT CUSTOMER ================================ //
// ============================================================================================== //

export const createCart = async (
  product,
  goodlightPoster,
  greetingCard,
  rebuy
) => {
  try {
    //     if (greetingCard !== undefined) {
    //       if (product.sellingPlanId) {
    //         const query = `mutation {
    //     cartCreate(
    //       input: {
    //         discountCodes: [
    //           "GREETINGCARD"
    //         ],
    //       lines: [{
    //         merchandiseId: "${product.merchandiseId}",
    //         quantity: ${product.quantity},
    //         sellingPlanId: "${product.sellingPlanId}"
    //       }, {
    //         merchandiseId: "${goodlightPoster.merchandiseId}",
    //         quantity: ${goodlightPoster.quantity},
    //       }, {
    //         merchandiseId: "${greetingCard.merchandiseId}",
    //         quantity: ${greetingCard.quantity},
    //       }],
    //       attributes: { key: "cart_attribute", value: "This is a cart attribute" }
    //     }
    //   ) {
    //     cart {
    //       id
    //       checkoutUrl
    //       lines(first: 25) {
    //         nodes {
    //           id
    //           sellingPlanAllocation {
    //             sellingPlan {
    //               id
    //               name
    //             }
    //             priceAdjustments {
    //               price {
    //                 amount
    //               }
    //             }
    //           }
    //           merchandise {
    //             ... on ProductVariant {
    //               id
    //               title
    //               image {
    //                 url
    //               }
    //               priceV2 {
    //                 amount
    //               }
    //             }
    //           }
    //           quantity
    //         }
    //       }
    //       attributes {
    //         key
    //         value
    //       }
    //       estimatedCost {
    //         totalAmount {
    //           amount
    //           currencyCode
    //         }
    //         subtotalAmount {
    //           amount
    //           currencyCode
    //         }
    //         totalTaxAmount {
    //           amount
    //           currencyCode
    //         }
    //         totalDutyAmount {
    //           amount
    //           currencyCode
    //         }
    //       }
    //     }
    //     userErrors {
    //       field
    //       code
    //       message
    //     }
    //   }
    // }
    // `;

    //         const response = await ShopifyData(query);
    //         return response.data.cartCreate.cart;
    //       } else {
    //         const query = `mutation {
    //       cartCreate(
    //         input: {
    //           discountCodes: [
    //             "GREETINGCARD"
    //           ],
    //         lines: [{
    //           merchandiseId: "${product.merchandiseId}",
    //           quantity: ${product.quantity}
    //         },{
    //           merchandiseId: "${goodlightPoster.merchandiseId}",
    //           quantity: ${goodlightPoster.quantity}
    //         }, {
    //           merchandiseId: "${greetingCard.merchandiseId}",
    //           quantity: ${greetingCard.quantity}
    //         }],
    //         attributes: { key: "cart_attribute", value: "This is a cart attribute" }
    //       }
    //     ) {
    //       cart {
    //         id
    //         checkoutUrl
    //         lines(first: 25) {
    //           nodes {
    //             id
    //             sellingPlanAllocation {
    //               sellingPlan {
    //                 id
    //                 name
    //               }
    //               priceAdjustments {
    //                 price {
    //                   amount
    //                 }
    //               }
    //             }
    //             merchandise {
    //               ... on ProductVariant {
    //                 id
    //                 title
    //                 image {
    //                   url
    //                 }
    //                 priceV2 {
    //                   amount
    //                 }
    //               }
    //             }
    //             quantity
    //           }
    //         }
    //         attributes {
    //           key
    //           value
    //         }
    //         estimatedCost {
    //           totalAmount {
    //             amount
    //             currencyCode
    //           }
    //           subtotalAmount {
    //             amount
    //             currencyCode
    //           }
    //           totalTaxAmount {
    //             amount
    //             currencyCode
    //           }
    //           totalDutyAmount {
    //             amount
    //             currencyCode
    //           }
    //         }
    //       }
    //       userErrors {
    //         field
    //         code
    //         message
    //       }
    //     }
    //   }
    //   `;

    //         const response = await ShopifyData(query);
    //         return response.data.cartCreate.cart;
    //       }
    //     } else {
    if (product.sellingPlanId) {
      if (rebuy) {
        const query = `mutation {
      cartCreate(
        input: {
        lines: [{
          merchandiseId: "${product.merchandiseId}",
          quantity: ${product.quantity},
          sellingPlanId: "${product.sellingPlanId}",
          attributes: [{key: "_source", value: "Rebuy"}, {key: "_widget_id", value: "78696"}]
        }]
      }
    ) {
      cart {
        id
        checkoutUrl
        lines(first: 25) {
          nodes {
            id
            sellingPlanAllocation {
              sellingPlan {
                id
                name
              }
              priceAdjustments { 
                price {
                  amount
                }
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                priceV2 {
                  amount
                }
              }
            }
            quantity
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        code
        message
      }
    }
  }
  `;

        const response = await ShopifyData(query);
        return response.data.cartCreate.cart;
      } else {
        const query = `mutation {
          cartCreate(
            input: {
            lines: [{
              merchandiseId: "${product.merchandiseId}",
              quantity: ${product.quantity},
              sellingPlanId: "${product.sellingPlanId}"
            }],
            attributes: { key: "cart_attribute", value: "This is a cart attribute" }
          }
        ) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            code
            message
          }
        }
      }
      `;

        const response = await ShopifyData(query);
        return response.data.cartCreate.cart;
      }
    } else {
      if (rebuy) {
        const query = `mutation {
          cartCreate(
            input: {
            lines: [{
              merchandiseId: "${product.merchandiseId}",
              quantity: ${product.quantity}
            }],
            attributes: [{key: "_source", value: "Rebuy"}, {key: "_widget_id", value: "78696"}]
          }
        ) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            field
            code
            message
          }
        }
      }
      `;

        const response = await ShopifyData(query);

        return response.data.cartCreate.cart;
      } else {
        const query = `mutation {
        cartCreate(
          input: {
          lines: [{
            merchandiseId: "${product.merchandiseId}",
            quantity: ${product.quantity}
          }],
          attributes: { key: "cart_attribute", value: "This is a cart attribute" }
        }
      ) {
        cart {
          id
          checkoutUrl
          lines(first: 25) {
            nodes {
              id
              sellingPlanAllocation {
                sellingPlan {
                  id
                  name
                }
                priceAdjustments { 
                  price {
                    amount
                  }
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                  priceV2 {
                    amount
                  }
                }
              }
              quantity
            }
          }
          attributes {
            key
            value
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
        userErrors {
          field
          code
          message
        }
      }
    }
    `;

        const response = await ShopifyData(query);
        return response.data.cartCreate.cart;
      }
    }
    // }
  } catch (error) {
    console.log(error);
  }
};
