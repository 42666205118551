import { ShopifyData } from "../Query";

// ============================================================================================== //
// ====================================== REMOVE ITEM FROM CART ================================= //
// ============================================================================================== //

export const removeItemFromCart = async (cartId, product) => {
  const query = `mutation {
      cartLinesRemove(cartId: "${cartId}", lineIds: ["${product.lineId}"]) {
        cart {
          id
          checkoutUrl
          lines(first: 25) {
            nodes {
              id
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                  priceV2 {
                    amount
                  }
                }
              }
              quantity
            }
          }
          attributes {
            key
            value
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
        userErrors {
          code
          field
          message
        }
      }
    }`;
  const response = await ShopifyData(query);
  return response.data.cartLinesRemove.cart;
};
