import React, { useContext, useState, useEffect } from "react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { CartContext } from "../../../context/shopContext";
import Cart from "../cart";
import NavLinks from "./Desktop/NavLinks";
import Sidebar from "./Mobile/Sidebar";
import DesktopSearch from "./Desktop/Search";
import RightSideIcons from "./Desktop/RightSideIcons";

const Navbar = () => {
  const data = useStaticQuery(graphql`
    {
      sanityMenu(id: { eq: "-6b8f6a85-f87c-5388-a067-2e127386cffb" }) {
        id
        navMenu {
          key: _key
          menutitle
          slug {
            current
          }
        }
      }
    }
  `);

  const { products, searchResults, setSearchResults, cart } =
    useContext(CartContext);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [quantity, setQuantity] = useState(0);

  function handleSidebar() {
    setIsOpen(!isOpen);
  }

  function handleSearch(e) {
    e.preventDefault();
    navigate(`/search?s=${encodeURIComponent(search)}`);
    setIsOpen(false);
    setSearch("");
  }

  useEffect(() => {
    if (search) {
      const results = products.filter((product) => {
        return product.title.toLowerCase().includes(search.toLowerCase());
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [search, setSearchResults]);

  useEffect(() => {
    const totalQuantity = cart.reduce((accumulator, item) => {
      return accumulator + item.quantity;
    }, 0);

    setQuantity(totalQuantity);
  }, [cart]);

  return (
    <div className="relative w-full">
      <div className="w-full  px-6 sm:px-10 flex items-center justify-between font-dinnext bg-white">
        {/* Links */}
        <NavLinks data={data} />
        {/* Sidebar */}
        <Sidebar
          data={data}
          isOpen={isOpen}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          setIsOpen={setIsOpen}
        />
        {isOpen && (
          <DesktopSearch
            search={search}
            setSearch={setSearch}
            setIsOpen={setIsOpen}
            searchResults={searchResults}
            handleSearch={handleSearch}
          />
        )}
        {/* Menu Bar */}
        <div className="block sm:hidden" onClick={handleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>
        {/* Logo */}
        <Link className="" to="/">
          <StaticImage
            className="w-[202px] h-[72px] object-cover"
            src="../../../images/logo.png"
            alt="Logo"
            layout="fullWidth"
            placeholder="none"
            loading="eager"
          />
        </Link>
        {/* Icons */}
        <RightSideIcons setIsOpen={setIsOpen} quantity={quantity} />
      </div>
      {/* Cart */}
      <div className="w-screen">
        {" "}
        <Cart quantity={quantity} />
      </div>
    </div>
  );
};

export default Navbar;
