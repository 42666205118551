import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import { GatsbyImage } from "gatsby-plugin-image";

const Navigation = ({ open, setOpen }) => {
  const { sanityMenu } = useStaticQuery(graphql`
    {
      sanityMenu(id: { eq: "-6b8f6a85-f87c-5388-a067-2e127386cffb" }) {
        megamenuitems {
          itemtitle
          itemslug {
            current
          }
          itemimage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
      }
    }
  `);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");

  const [selectedCategory, setSelectedCategory] = useState(category || "");

  useEffect(() => {
    if (category === "all-products") {
      setSelectedCategory(category);
    } else if (category === "best-sellers") {
      setSelectedCategory(category);
    } else if (category === "core-collection") {
      setSelectedCategory(category);
    } else if (category === "bundles") {
      setSelectedCategory(category);
    }
  }, [category]);

  return (
    <div
      className={`absolute top-[65px] left-0 w-screen h-auto bg-white border-t border-solid border-t-[#E7E7E7] transition-opacity duration-300 ease-linear
    ${open ? "opacity-100 block" : "opacity-0 hidden"}`}
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className="w-full h-full px-8 md:px-[60px] py-8 flex space-x-5 md:space-x-14">
        {/* Container 1 */}
        <div className="space-y-7 uppercase font-dinnext w-48 h-52">
          <h5 className="">
            <Link
              to="/collections/good-light-products?category=all-products"
              className={`uppercase hover:text-black hover:underline underline-offset-4 transition-all duration-200 ease-linear text-black`}
              onClick={() => setOpen(false)}
            >
              All Products
            </Link>
          </h5>
          <h5 className="">
            <Link
              to="/collections/good-light-products?category=best-sellers"
              className={`uppercase hover:text-black hover:underline underline-offset-4 transition-all duration-200 ease-linear text-black`}
              onClick={() => setOpen(false)}
            >
              Best Sellers
            </Link>
          </h5>
          <h5 className="">
            <Link
              to="/collections/good-light-products?category=bundles"
              className={`uppercase hover:text-black hover:underline underline-offset-4 transition-all duration-200 ease-linear text-black`}
              onClick={() => setOpen(false)}
            >
              Bundles
            </Link>
          </h5>
        </div>
        {/* Container 2 */}
        <div className="space-x-5 lg:space-x-[50px] flex w-full h-full">
          {sanityMenu.megamenuitems?.map((item, index) => (
            <Link
              key={index}
              to={
                item.itemslug.current.startsWith("/")
                  ? item.itemslug.current
                  : `/${item.itemslug.current}`
              }
              className="max-h-[150px] lg:max-h-[180px] w-full space-y-[14px] group"
            >
              <GatsbyImage
                image={item.itemimage.asset.gatsbyImageData}
                alt={item.itemtitle}
                loading="lazy"
                className="w-full h-full object-cover border border-solid border-[#EEEEEE] rounded-md"
              />
              <h5 className="text-base group-hover:underline group-hover:underline-offset-4">
                {item.itemtitle}
              </h5>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
