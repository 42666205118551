import { graphql, Link, useStaticQuery } from "gatsby";
import React, { Fragment, useContext } from "react";
import { CartContext } from "../../../../context/shopContext";
import { useWindowDimension } from "../../../utils/useWindowDimension";

const Sidebar = ({
  data,
  isOpen,
  search,
  setSearch,
  handleSearch,
  setIsOpen,
}) => {
  const { searchResults, myProfile, logoutAccount } = useContext(CartContext);
  const { height } = useWindowDimension();
  const { bestSeller, coreCollections, bundles } = useStaticQuery(graphql`
    {
      bestSeller: shopifyCollection(
        id: { eq: "10af02d0-b9bc-580a-8604-e6e3287bbbe0" }
      ) {
        title
        handle
      }
      coreCollections: shopifyCollection(
        id: { eq: "71dca8c4-ea4e-50e8-8bdf-16f531b35719" }
      ) {
        title
        handle
      }
      bundles: shopifyCollection(
        id: { eq: "12d83c96-49cf-55fc-9f73-756e436d69c6" }
      ) {
        title
        handle
      }
    }
  `);
  return (
    <div
      className={`${
        isOpen ? "left-0" : "-left-[100vw]"
      } fixed top-0 left-0 z-20 w-screen h-screen bg-[black/30]`}
      onClick={() => setIsOpen(false)}
    >
      <div
        className={`fixed top-[120px] ${
          isOpen ? "left-0" : "-left-80"
        } h-screen flex flex-col max-w-[320px] w-full sm:hidden bg-[#F8F8F8] z-20 font-dinnext transform transition-all duration-300
        ease-linear border-none`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative pt-5 pl-10">
          {/* Close Icon */}
          <button
            className="absolute top-5 right-4 text-black"
            aria-label="button"
            onClick={() => setIsOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
          {/* Search */}
          <div className="w-56 rounded-full h-12 bg-[#D9D9D96E] flex items-center mb-5 mt-0">
            <input
              type="text"
              placeholder="Search"
              className="flex-1 h-full rounded-full bg-transparent focus:outline-none px-2"
              value={search}
              autoFocus
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* Close Icon */}
            {search && (
              <Fragment>
                <svg
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-1"
                  fill="none"
                  stroke="#313131"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  onClick={() => setSearch("")}
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path
                    fill="currentColor"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                  ></path>
                </svg>
                {/* Search Icon */}
                {/* <svg
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#313131"
                  strokeWidth={2}
                  // onClick={}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg> */}
              </Fragment>
            )}
          </div>
          {searchResults.length > 0 && (
            <div className="w-full h-auto py-3 mt-2 z-30 relative">
              <ul className="space-y-2 font-dinnext text-black">
                <h6 className="text-xs">Products</h6>
                {searchResults.slice(0, 2).map((result, index) => (
                  <li
                    className="w-full flex items-center py-2 px-2 hover:bg-white/90"
                    key={index}
                    onClick={() => setIsOpen(false)}
                  >
                    <Link
                      className="text-black flex items-center"
                      to={`/products/${result.handle}`}
                    >
                      <img
                        src={result.featuredImage.src}
                        alt={result.title}
                        className="h-16 w-16 object-contain rounded-full"
                      />
                      <h5 className="ml-5">{result.title}</h5>
                    </Link>
                  </li>
                ))}
                {searchResults.length > 2 && (
                  <div className="w-full border-t border-solid border-gray-500">
                    <button
                      className="px-2 text-sm underline bg-none my-2"
                      onClick={handleSearch}
                    >
                      View all
                    </button>
                  </div>
                )}
              </ul>
            </div>
          )}
          <ul className="w-full ml-0 space-y-1 sm:hidden uppercase font-semibold pr-10 list-none">
            {data.sanityMenu.navMenu.map((item, index) => (
              <li key={index}>
                <div
                  key={item.key}
                  className={`flex items-center border-b-2 border-solid border-b-[#70707012] py-3`}
                  onClick={() => setIsOpen(false)}
                >
                  <Link
                    className="text-black pb-1"
                    activeClassName="active_link"
                    to={item.slug.current}
                  >
                    {item.menutitle}
                  </Link>
                </div>
                {item.slug.current.includes(
                  "collections/good-light-products"
                ) && (
                  <ul
                    className={`ml-0 list-none text-sm underline font-dinnext lowercase font-medium border-b-2
                  border-solid border-b-[#70707012] py-3 space-y-4`}
                  >
                    <li onClick={() => setIsOpen(false)}>
                      <Link to="/collections/good-light-products?category=all-products">
                        All Products
                      </Link>
                    </li>
                    <li onClick={() => setIsOpen(false)}>
                      <Link
                        to={`/collections/good-light-products?category=${bestSeller.handle}`}
                      >
                        {bestSeller.title}
                      </Link>
                    </li>
                    {/* <li onClick={() => setIsOpen(false)}>
                      <Link
                        to={`/collections/good-light-products?category=${coreCollections.handle}`}
                      >
                        {coreCollections.title}
                      </Link>
                    </li> */}
                    <li onClick={() => setIsOpen(false)}>
                      <Link
                        to={`/collections/good-light-products?category=${bundles.handle}`}
                      >
                        {bundles.title}
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            ))}

            {myProfile && (
              <li
                className="flex items-center py-6 uppercase"
                onClick={() => setIsOpen(false)}
              >
                <button
                  className="text-black pb-1"
                  onClick={() => logoutAccount()}
                >
                  logout
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
