// import { useKlaviyoForceReload } from "@frontend-sdk/klaviyo";
import React, { useRef, useState } from "react";

const Subscription = () => {
  // useKlaviyoForceReload("SmJgeL");
  const emailRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    const email = emailRef.current?.value;

    const options = {
      method: "POST",
      headers: { revision: "2024-02-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            profile: {
              data: {
                type: "profile",
                attributes: {
                  email: email,
                },
              },
            },
          },
          relationships: { list: { data: { type: "list", id: "Xt79zw" } } },
        },
      }),
    };

    fetch(
      "https://a.klaviyo.com/client/subscriptions/?company_id=SmJgeL",
      options
    )
      .then((response) => {
        if (response.ok) {
          emailRef.current.value = "";
          setSuccess(true);
        }
      })
      .catch((error) => console.error("Error ---> ", error));
    // fetch(`https://manage.kmail-lists.com/ajax/subscriptions/subscribe`, {
    //   method: "POST",
    //   body: urlData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.data.is_subscribed) {
    //       setSuccess(data.data.is_subscribed);
    //     }
    //   })
    //   .catch((error) => console.error("Error ---> ", error));
  };
  return (
    <div className="w-full">
      <div className="w-full">
        <p className="text-base font-dinnext">
          Join our mailing list to receive 10% off your first order
        </p>
        {/* <div className="klaviyo-form-UZ6byW" id="klaviyo-form"></div> */}
        <div className="w-full flex mt-[13px]">
          <form className="w-full max-w-2xl" onSubmit={onSubmit}>
            <div className="w-full flex flex-row">
              <input
                type="email"
                name="EMAIL"
                ref={emailRef}
                className="w-full h-[51px] sm:h-[58px] bg-white border border-solid border-black pl-5 py-[19px] text-lg pr-5 font-dinnext font-light"
                id="mce-EMAIL"
                placeholder="Email Address"
              />
              <button
                type="submit"
                className="w-fit h-[51px] sm:h-[58px] bg-black text-lg font-dinnext font-medium px-[14px] sm:px-[55px]"
              >
                <span className="text-white">Subscribe</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
