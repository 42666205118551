import React, { useContext, useEffect } from "react";
import { CartContext } from "../../../context/shopContext";
import { useWindowDimension } from "./../../utils/useWindowDimension";

const CartContentSecondary = () => {
  const { height } = useWindowDimension();
  const { cart, disabled, updateQuantityCart, removeFromCart } =
    useContext(CartContext);

  function handleUpdateCart(product) {
    updateQuantityCart(product);
  }

  function handleRemoveFromCart(product) {
    removeFromCart(product);
  }

  const shippedItemsHandles = [
    "shipped-shield-package-assurance",
    "shipped-green-carbon-neutral-shipment",
  ];

  useEffect(() => {
    const shippedItems = cart.filter((item) =>
      shippedItemsHandles.includes(item.handle)
    );

    if (shippedItems.length > 0 && shippedItems.length === cart.length) {
      shippedItems.forEach((item) => handleRemoveFromCart(item));
    }
  }, [cart]);

  function filterOutShippedItems(item) {
    return !shippedItemsHandles.includes(item.handle);
  }

  return (
    <div className={`w-full h-full overflow-y-scroll scrollbar-hide max-h-36`}>
      {cart.filter(filterOutShippedItems).length > 0 && (
        <div className="w-full">
          <div className="w-auto h-full overflow-y-auto flex gap-6 scrollbar-hide">
            {cart.filter(filterOutShippedItems).map((item, index) => (
              <div
                key={index}
                className="relative h-full bg-[#D9D9D92B] px-[9px] py-2 w-fit min-w-[350px]"
              >
                {/* Remove Button */}
                <button
                  className="ml-auto w-7 h-7 bg-[#EDEDED] flex items-center justify-center text-black disabled:text-gray-400
                text-center cursor-pointer"
                  disabled={Number(item.price) === 0}
                  onClick={() => handleRemoveFromCart(item)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {/* Image */}
                <div className="flex items-center w-full h-full space-x-2 -mt-2">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-16 h-16 object-cover rounded-full overflow-hidden"
                  />
                  <div className="space-y-2">
                    <h4 className="font-dinnext capitalize">{item.title}</h4>{" "}
                    <h5 className="mx-auto block text-base font-dinnext">
                      {item.price ? (
                        <span>$ {Number(item.price).toFixed(2)}</span>
                      ) : (
                        "Free"
                      )}
                    </h5>
                  </div>
                </div>
                {/* Quantity Button */}
                <div className="w-min ml-auto">
                  {item.price && Number(item.price).toFixed(2) > 0 && (
                    <div className="flex items-center justify-center text-white text-xl w-[122px]">
                      <button
                        className="w-7 h-7 bg-[#EDEDED] text-black cursor-pointer"
                        disabled={disabled}
                        onClick={() =>
                          handleUpdateCart({
                            ...item,
                            quantity: item.quantity - 1,
                          })
                        }
                      >
                        -
                      </button>
                      <span className="w-12 h-7 text-lg text-center font-dinnext text-black">
                        {item.quantity || 1}
                      </span>
                      <button
                        className="w-7 h-7 bg-black cursor-pointer "
                        disabled={disabled}
                        onClick={() =>
                          handleUpdateCart({
                            ...item,
                            quantity: item.quantity + 1,
                          })
                        }
                      >
                        +
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartContentSecondary;
