import { ShopifyData } from "../Query";

// ============================================================================================== //
// ================================ BUYER IDENTITY UPDATE IN CART =============================== //
// ============================================================================================== //

export const buyerIdentityUpdateInCart = async (cartId, customer) => {
  const query = `mutation {
      cartBuyerIdentityUpdate(cartId: "${cartId}", buyerIdentity: { customerAccessToken: "${customer.accessToken}", email: "${customer.email}", }) {
        cart {
          id
          checkoutUrl
          buyerIdentity {
            email
            countryCode
            customer {
              displayName
            }
          }
          lines(first: 25) {
            nodes {
              id
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                  priceV2 {
                    amount
                  }
                }
              }
              quantity
            }
          }
          attributes {
            key
            value
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
        userErrors {
          code
          field
          message
        }
      }
    }
    `;
  const response = await ShopifyData(query);
  return response.data.cartBuyerIdentityUpdate.cart;
};
