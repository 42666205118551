import { Link } from "gatsby";
import React from "react";

const DesktopSearch = ({
  search,
  setSearch,
  setIsOpen,
  searchResults,
  handleSearch,
}) => {
  return (
    <div
      className="fixed hidden sm:block top-0 left-0 h-screen w-screen bg-black/30 z-20 font-dinnext"
      onClick={() => setIsOpen(false)}
    >
      <div className="relative">
        {/* Search */}
        <div
          className="absolute right-12 top-16 w-[450px] bg-black"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center w-full h-12 px-3 space-x-1">
            <input
              type="text"
              placeholder="What are you looking for?"
              className="flex-1 h-4 text-sm bg-transparent focus:outline-none text-white"
              autoFocus
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* Close Icon */}
            {search && (
              <svg
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-[#101010] sm:text-white"
                fill="none"
                stroke="#313131"
                strokeWidth={0}
                viewBox="0 0 24 24"
                onClick={() => setSearch("")}
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path
                  fill="currentColor"
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                ></path>
              </svg>
            )}
            {/* Search Icon */}
            <svg
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#ffffff"
              strokeWidth={2}
              // onClick={}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          {searchResults.length > 0 && (
            <div className="w-full h-auto py-3 mt-2 px-5">
              <ul className="space-y-2 font-dinnext text-white">
                <h6 className="text-xs">Products</h6>
                {searchResults.slice(0, 2).map((result) => (
                  <li
                    className="w-full flex items-center py-2 px-2 hover:bg-gray-800"
                    key={result.id}
                  >
                    <Link
                      className="text-white flex items-center"
                      to={`/products/${result.handle}`}
                    >
                      <img
                        src={result.featuredImage.src}
                        alt={result.title}
                        className="h-16 w-16 object-contain rounded-full overflow-hidden"
                      />
                      <h5 className="ml-5">{result.title}</h5>
                    </Link>
                  </li>
                ))}
                {searchResults.length > 2 && (
                  <div className="w-full border-t border-solid border-gray-500">
                    <button
                      className="px-2 text-sm underline bg-none my-2"
                      onClick={handleSearch}
                    >
                      View all
                    </button>
                  </div>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopSearch;
