import React, { useContext, useRef } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CartContext } from "../../context/shopContext";
import Subscription from "./subscription";
import useIsVisible from "../../hooks/isVisible";

const Footer = () => {
  const { sanityFooter } = useStaticQuery(graphql`
    {
      sanityFooter {
        logo {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 150
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logoCaption
        subfooterLinks {
          menutitle
          slug {
            current
          }
        }
        infoLinks {
          menutitle
          slug {
            current
          }
        }
        helpLinks {
          menutitle
          slug {
            current
          }
        }
        contactEmail
        socialLinks {
          title
          url
          iconImage {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 50
                height: 50
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
      }
    }
  `);
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);

  // const { myProfile } = useContext(CartContext);

  return (
    <div className="bg-[rgba(233,211,218,0.60)] flex flex-col-reverse md:flex-col">
      <div className="block md:hidden pb-[42px] px-3 sm:px-10">
        <span className="text-xs font-dinnext font-light mb-[2px]">
          Contact Us
        </span>
        <div className="flex flex-col space-y-4">
          <a
            className="font-dinnext font-medium"
            href={`mailto:${sanityFooter.contactEmail}`}
          >
            {sanityFooter.contactEmail}
          </a>
          <div className="flex items-center space-x-[17px]">
            {sanityFooter.socialLinks.map((link, key) => (
              <a
                key={key}
                href={link.url}
                target="_blank"
                rel="norefferer"
                className="w-[25px] h-[25px]"
              >
                <GatsbyImage
                  image={link.iconImage.asset.gatsbyImageData}
                  alt={link.title}
                  className="w-[25px] h-[25px] object-contain"
                  objectFit="contain"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full px-3 sm:px-10 flex items-start justify-between py-[42px] md:py-20 flex-col-reverse md:flex-row">
        <div className="space-x-[99px] md:space-x-[106px] flex mt-[31px] md:mt-0">
          <div className="flex flex-col space-y-1">
            <span className="text-base leading-[26px] capitalize font-dinnext font-medium">
              Information
            </span>
            {sanityFooter.infoLinks.map((link, index) => (
              <Link
                key={index}
                to={link.slug.current}
                className="text-base leading-[26px] capitalize font-dinnext font-light"
              >
                {link.menutitle}
              </Link>
            ))}
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-base leading-[26px] capitalize font-dinnext font-medium">
              Help
            </span>
            {sanityFooter.helpLinks.map((link, index) => (
              <Link
                key={index}
                to={link.slug.current}
                className="text-base leading-[26px] capitalize font-dinnext font-light"
              >
                {link.menutitle}
              </Link>
            ))}
          </div>
        </div>
        <div className="xl:flex-[0.5]">
          <div ref={elemRef} className="in-view">
            {isVisible && <Subscription />}
          </div>
          <div className="hidden md:block mt-[55px]">
            <span className="text-xs font-dinnext font-light mb-[2px]">
              Contact Us
            </span>
            <div className="flex items-end space-x-4">
              <a href={`mailto:${sanityFooter.contactEmail}`}>
                {sanityFooter.contactEmail}
              </a>
              <div className="flex items-center space-x-[17px]">
                {sanityFooter.socialLinks.map((link, key) => (
                  <a
                    key={key}
                    href={link.url}
                    target="_blank"
                    rel="norefferer"
                    className="w-[25px] h-[25px]"
                  >
                    <GatsbyImage
                      image={link.iconImage.asset.gatsbyImageData}
                      alt={link.title}
                      className="w-[25px] h-[25px] object-contain"
                      objectFit="contain"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      {/* Subfooter */}
      <div className="w-full h-[91px] md:h-20 flex items-center justify-between pl-2 sm:pl-6 pr-3 sm:pr-11 border-t border-solid border-t-[#C4C4C4]">
        <div>
          <GatsbyImage
            image={sanityFooter.logo.asset.gatsbyImageData}
            alt="Goodlight"
            className="w-[132px] h-9"
            objectFit="cover"
          />
          <p className="text-[9.5px] ml-[14px] font-dinnext font-light">
            {sanityFooter.logoCaption}
          </p>
        </div>
        <div className="md:space-x-10 w-40 md:w-auto flex flex-col md:flex-row items-start md:items-center">
          {sanityFooter.subfooterLinks.map((link, index) => (
            <Link
              key={index}
              to={link.slug.current}
              className="text-sm sm:text-base leading-[26px] capitalize font-dinnext font-light"
            >
              {link.menutitle}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
