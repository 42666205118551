import { ShopifyData } from "../Query";

// ============================================================================================== //
// ================================ GET CART WITHOUT CUSTOMER ================================ //
// ============================================================================================== //

export const getCart = async (id) => {
  const query = `query{
      cart(id: "${id}") {
        id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    product {
                      id
                      title
                      handle
                    }
                    availableForSale
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
      }
    }`;

  const response = await ShopifyData(query);
  return response?.data.cart;
};
