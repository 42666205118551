import React, { useContext } from "react";
import { Link } from "gatsby";
import { CartContext } from "../../../../context/shopContext";

const RightSideIcons = ({ quantity, setIsOpen }) => {
  const { cart, cartOpen, setCartOpen, myProfile, logoutAccount } =
    useContext(CartContext);

  function handleCart() {
    setCartOpen(!cartOpen);
  }
  return (
    <div className="flex items-center md:flex-[0.4] justify-end">
      <Link className="hidden sm:block" to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#313131"
          strokeWidth={2}
          onClick={() => setIsOpen(true)}
          aria-label="search-icon"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </Link>
      <Link
        className="hidden sm:block ml-6"
        to={`${myProfile ? "/account/" : "/account/login/"}`}
        activeClassName="active_link"
      >
        account
      </Link>
      {myProfile && (
        <button
          className="hidden sm:block ml-6 bg-black text-white px-3 py-1"
          onClick={() => logoutAccount()}
        >
          Logout
        </button>
      )}
      <button className="sm:ml-6 relative cursor-pointer" aria-label="button" onClick={handleCart}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          fill="none"
          viewBox="0 0 21 20"
        >
          <g fill="#313131" clipPath="url(#clip0_348_244)">
            <path d="M15.47 11.09a.859.859 0 00.74-.43l4.16-7.29a.82.82 0 000-.84.83.83 0 00-.73-.42H4.19L3.46.54A.84.84 0 002.7.05H.01v1.7h2.15l4 8.47-1.79 2.69a.85.85 0 00.11 1.07l1 1a.86.86 0 00.6.25h12.38v-1.69h-12l-.31-.31 1.42-2.13 7.9-.01zM4.98 3.76h13.2l-3.2 5.6H7.61l-2.63-5.6zM6.12 16.91a1.55 1.55 0 00-1.55 1.55 1.55 1.55 0 003.1 0 1.55 1.55 0 00-1.55-1.55zM16.41 16.91a1.54 1.54 0 00-1.55 1.55A1.54 1.54 0 0016.41 20a1.54 1.54 0 001.54-1.54 1.539 1.539 0 00-1.54-1.55z"></path>
          </g>
          <defs>
            <clipPath id="clip0_348_244">
              <path fill="#fff" d="M0 0H20.48V20H0z"></path>
            </clipPath>
          </defs>
        </svg>
        {cart.length > 0 && (
          <span
            className="absolute w-5 h-5 rounded-full bg-black -right-2 -bottom-2 z-10 text-white text-xs font-dinnext
    flex items-center justify-center text-center"
          >
            {quantity}
          </span>
        )}
      </button>
    </div>
  );
};

export default RightSideIcons;
