import React from "react";
import Layout from "./src/components/common/index";
import ShopProvider from "./src/context/shopContext";
import "./src/styles/global.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const wrapPageElement = ({ element }) => {
  return (
    <ShopProvider>
      <Layout>{element}</Layout>
    </ShopProvider>
  );
};
