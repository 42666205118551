import { Link } from "gatsby";
import React, { useState } from "react";
import Navigation from "./Navigation";

const NavLinks = ({ data }) => {
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  const [openNavigation, setOpenNavigation] = useState(false);

  function handleMouseOver(item) {
    if (item.slug.current.includes("collections/good-light-products")) {
      setOpenNavigation(true);
    } else if (!item.slug.current.includes("collections/good-light-products")) {
      setOpenNavigation(false);
    }
  }

  function handleMouseLeave(item) {
    if (!item.slug.current.includes("collections/good-light-products")) {
      setOpenNavigation(false);
    }
  }

  return (
    <nav
      className="hidden sm:flex items-center md:flex-[0.4]"
      onMouseLeave={() => setOpenNavigation(false)}
    >
      {data.sanityMenu.navMenu.map((item) => (
        <Link
          key={item.key}
          className={`first-of-type:ml-0 ml-[18px] pb-[2px] flex items-center h-[72px] `}
          activeClassName="active_link"
          to={item.slug.current}
          onMouseOver={() => handleMouseOver(item)}
          onMouseLeave={() => handleMouseLeave(item)}
        >
          <span
            className={`${
              item.slug.current.includes("collections/good-light-products") &&
              path.includes("/products/")
                ? "border-b border-solid border-b-black"
                : ""
            }`}
          >
            {item.menutitle}
          </span>
        </Link>
      ))}
      <Navigation open={openNavigation} setOpen={setOpenNavigation} />
    </nav>
  );
};

export default NavLinks;
