import React, { useEffect, useRef, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Navbar from "./navbar";

const PromoBar = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const timeoutRef = useRef(null);
  const delay = 4000;

  const data = useStaticQuery(graphql`
    {
      allSanityPromobar {
        nodes {
          id
          title
          slug
        }
      }
    }
  `);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentSlide((prevIndex) =>
          prevIndex === data?.allSanityPromobar?.nodes.length - 1
            ? 0
            : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [currentSlide]);
  return (
    <div className="fixed -top-[2px] left-0 w-full z-50">
      <div
        className={`whitespace-nowrap w-full transition-all duration-500 ease-linear h-[51px]`}
        // style={{ transform: `translate3d(${-currentSlide * 100}%, 0, 0)` }}
      >
        {/* {data?.allSanityPromobar?.nodes.map((item, index) => ( */}
        <Link
          to={data.allSanityPromobar?.nodes[currentSlide].slug}
          className="inline-block w-full h-[51px] bg-black"
        >
          <p className="w-full h-full flex items-center justify-center text-white text-center font-dinnext font-light">
            {data.allSanityPromobar?.nodes[currentSlide].title}
          </p>
        </Link>
        {/* ))} */}
      </div>
      <div className="w-full mx-auto bg-white">
        <Navbar />
      </div>
    </div>
  );
};

export default PromoBar;
