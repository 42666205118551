import { ShopifyData } from "../Query";

// ============================================================================================== //
// ========================================== UPDATE CART ======================================= //
// ============================================================================================== //

export const updateCart = async (cartId, product) => {
  if (product.sellingPlanId) {
    const query = `mutation {
      cartLinesUpdate(cartId: "${cartId}", lines: [{
        id: "${product.lineId}",
        quantity: ${product.quantity},
        merchandiseId: "${product.shopifyId}",
        sellingPlanId: "${product.sellingPlanId}"
      }]) {
        cart {
          id
          checkoutUrl
          buyerIdentity {
            email
            countryCode
            customer {
              displayName
            }
          }
          lines(first: 25) {
            nodes {
              id
              sellingPlanAllocation {
                sellingPlan {
                  id
                  name
                }
                priceAdjustments { 
                  price {
                    amount
                  }
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                  priceV2 {
                    amount
                  }
                }
              }
              quantity
            }
          }
          attributes {
            key
            value
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
        }
        userErrors {
          code
          field
          message
        }
      }
    }`;
    const response = await ShopifyData(query);
    return response.data.cartLinesUpdate.cart;
  } else {
    const query = `mutation {
        cartLinesUpdate(cartId: "${cartId}", lines: [{id: "${product.lineId}", quantity: ${product.quantity}, merchandiseId: "${product.shopifyId}"}]) {
          cart {
            id
            checkoutUrl
            buyerIdentity {
              email
              countryCode
              customer {
                displayName
              }
            }
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }`;
    const response = await ShopifyData(query);
    return response.data.cartLinesUpdate.cart;
  }
};
