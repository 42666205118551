import { ShopifyData } from "../Query";

// ============================================================================================== //
// ================================== CREATE CART WITH CUSTOMER ================================= //
// ============================================================================================== //

export const createCartWithCustomer = async (
  product,
  goodlightPoster,
  greetingCard,
  customer,
  rebuy
) => {
  //   if (greetingCard !== undefined) {
  //     if (product.sellingPlanId) {
  //       const query = `mutation {
  //   cartCreate(
  //     input: {
  //       discountCodes: [
  //         "GREETINGCARD"
  //       ],
  //       lines: [{
  //         merchandiseId: "${product.merchandiseId}",
  //         quantity: ${product.quantity},
  //         sellingPlanId: "${product.sellingPlanId}"
  //       }, {
  //         merchandiseId: "${goodlightPoster.merchandiseId}",
  //         quantity: ${goodlightPoster.quantity},
  //       }, {
  //         merchandiseId: "${greetingCard.merchandiseId}",
  //         quantity: ${greetingCard.quantity},
  //       }],
  //       buyerIdentity: {customerAccessToken: "${customer.token}", email: "${customer.email}"}
  //       attributes: { key: "cart_attribute", value: "This is a cart attribute" }
  //     }
  //   ) {
  //     cart {
  //       id
  //       checkoutUrl
  //       lines(first: 25) {
  //         nodes {
  //           id
  //           sellingPlanAllocation {
  //             sellingPlan {
  //               id
  //               name
  //             }
  //             priceAdjustments {
  //               price {
  //                 amount
  //               }
  //             }
  //           }
  //           merchandise {
  //             ... on ProductVariant {
  //               id
  //               title
  //               image {
  //                 url
  //               }
  //               priceV2 {
  //                 amount
  //               }
  //             }
  //           }
  //           quantity
  //         }
  //       }
  //       attributes {
  //         key
  //         value
  //       }
  //       estimatedCost {
  //         totalAmount {
  //           amount
  //           currencyCode
  //         }
  //         subtotalAmount {
  //           amount
  //           currencyCode
  //         }
  //         totalTaxAmount {
  //           amount
  //           currencyCode
  //         }
  //         totalDutyAmount {
  //           amount
  //           currencyCode
  //         }
  //       }
  //     }
  //     userErrors {
  //       code
  //       field
  //       message
  //     }
  //   }
  // }
  // `;

  //       const response = await ShopifyData(query);
  //       return response.data.cartCreate.cart;
  //     } else {
  //       const query = `mutation {
  //       cartCreate(
  //         input: {
  //           discountCodes: [
  //             "GREETINGCARD"
  //           ],
  //           lines: [{
  //             merchandiseId: "${product.merchandiseId}",
  //             quantity: ${product.quantity},
  //             sellingPlanId: "${product.sellingPlanId}"
  //           }, {
  //             merchandiseId: "${goodlightPoster.merchandiseId}",
  //             quantity: ${goodlightPoster.quantity},
  //           }, {
  //             merchandiseId: "${greetingCard.merchandiseId}",
  //             quantity: ${greetingCard.quantity},
  //           }],
  //           buyerIdentity: {customerAccessToken: "${customer.token}", email: "${customer.email}"}
  //           attributes: { key: "cart_attribute", value: "This is a cart attribute" }
  //         }
  //       ) {
  //         cart {
  //           id
  //           checkoutUrl
  //           lines(first: 25) {
  //             nodes {
  //               id
  //               sellingPlanAllocation {
  //                 sellingPlan {
  //                   id
  //                   name
  //                 }
  //                 priceAdjustments {
  //                   price {
  //                     amount
  //                   }
  //                 }
  //               }
  //               merchandise {
  //                 ... on ProductVariant {
  //                   id
  //                   title
  //                   image {
  //                     url
  //                   }
  //                   priceV2 {
  //                     amount
  //                   }
  //                 }
  //               }
  //               quantity
  //             }
  //           }
  //           attributes {
  //             key
  //             value
  //           }
  //           estimatedCost {
  //             totalAmount {
  //               amount
  //               currencyCode
  //             }
  //             subtotalAmount {
  //               amount
  //               currencyCode
  //             }
  //             totalTaxAmount {
  //               amount
  //               currencyCode
  //             }
  //             totalDutyAmount {
  //               amount
  //               currencyCode
  //             }
  //           }
  //         }
  //         userErrors {
  //           code
  //           field
  //           message
  //         }
  //       }
  //     }
  //     `;

  //       const response = await ShopifyData(query);
  //       return response.data.cartCreate.cart;
  //     }
  //   } else {

  if (product.sellingPlanId) {
    if (rebuy) {
      const query = `mutation {
        cartCreate(
          input: {
            discountCodes: [
              "GREETINGCARD"
            ],
            lines: [{
              merchandiseId: "${product.merchandiseId}",
              quantity: ${product.quantity},
              sellingPlanId: "${product.sellingPlanId}",
              attributes: [{key: "_source", value: "Rebuy"}, {key: "_widget_id", value: "78696"}]
            }],
            buyerIdentity: {customerAccessToken: "${customer.token}", email: "${customer.email}"}
          }
        ) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

      const response = await ShopifyData(query);
      return response.data.cartCreate.cart;
    } else {
      const query = `mutation {
    cartCreate(
      input: {
        discountCodes: [
          "GREETINGCARD"
        ],
        lines: [{
          merchandiseId: "${product.merchandiseId}",
          quantity: ${product.quantity},
          sellingPlanId: "${product.sellingPlanId}"
        }],
        buyerIdentity: {customerAccessToken: "${customer.token}", email: "${customer.email}"}
        attributes: [{ key: "cart_attribute", value: "This is a cart attribute" }]
      }
    ) {
      cart {
        id
        checkoutUrl
        lines(first: 25) {
          nodes {
            id
            sellingPlanAllocation {
              sellingPlan {
                id
                name
              }
              priceAdjustments { 
                price {
                  amount
                }
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                priceV2 {
                  amount
                }
              }
            }
            quantity
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  `;

      const response = await ShopifyData(query);
      return response.data.cartCreate.cart;
    }
  } else {
    if (rebuy) {
      const query = `mutation {
        cartCreate(
          input: {
            discountCodes: [
              "GREETINGCARD"
            ],
            lines: [{
              merchandiseId: "${product.merchandiseId}",
              quantity: ${product.quantity},
            }],
            buyerIdentity: {customerAccessToken: "${customer.token}", email: "${customer.email}"}
            attributes: [{ _source: "Rebuy", _widget_id: "78696" }]
          }
        ) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

      const response = await ShopifyData(query);
      return response.data.cartCreate.cart;
    } else {
      const query = `mutation {
        cartCreate(
          input: {
            discountCodes: [
              "GREETINGCARD"
            ],
            lines: [{
              merchandiseId: "${product.merchandiseId}",
              quantity: ${product.quantity},
            }],
            buyerIdentity: {customerAccessToken: "${customer.token}", email: "${customer.email}"}
            attributes: { key: "cart_attribute", value: "This is a cart attribute" }
          }
        ) {
          cart {
            id
            checkoutUrl
            lines(first: 25) {
              nodes {
                id
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments { 
                    price {
                      amount
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                    }
                  }
                }
                quantity
              }
            }
            attributes {
              key
              value
            }
            estimatedCost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
              totalTaxAmount {
                amount
                currencyCode
              }
              totalDutyAmount {
                amount
                currencyCode
              }
            }
          }
          userErrors {
            code
            field
            message
          }
        }
      }
      `;

      const response = await ShopifyData(query);
      return response.data.cartCreate.cart;
    }
  }
  // }
};
