import { Script } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import SnapChatpixel from "../utils/snapchatpixel";
import Footer from "./footer";
import PromoBar from "./promobar";

const Layout = ({ children }) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      SnapChatpixel.init(
        "d73e4906-c86a-46f4-a428-06bdb022c92f",
        {},
        { debug: false }
      );
      SnapChatpixel.pageView();
      SnapChatpixel.snaptr("track", "PAGE_VIEW");

      setReady(true);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Add Klaviyo script to the document
    const script = document.createElement("script");
    script.src =
      "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SmJgeL";
    script.async = true;
    document.body.appendChild(script);

    // Initialize Klaviyo onsite
    window._klOnsite = window._klOnsite || [];

    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Fragment>
      {ready && (
        <>
          <Script
            strategy="idle"
            dangerouslySetInnerHTML={{
              __html: `!function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              ttq.load("C1MDQIPT0U322RQQABN0");
              ttq.page();
            }(window, document, 'ttq');`,
            }}
          />

          <Script
            strategy="idle"
            dangerouslySetInnerHTML={{
              __html: `(function(d){var s = d.createElement("script");s.setAttribute("data-account", "C8QJNKN7ru");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)`,
            }}
          />
          <Script strategy="idle" src="https://cdn.attn.tv/golight/dtag.js" />
          {/* <Script
            strategy="idle"
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SmJgeL"
          /> */}
        </>
      )}
      <div className="relative max-w-[100vw] w-full h-full mx-auto">
        <PromoBar />
        <div className="w-full mx-auto mt-32 ">
          {/* <Navbar /> */}
          {children}

          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
