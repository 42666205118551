exports.components = {
  "component---src-landing-page-index-jsx": () => import("./../../../src/LandingPage/index.jsx" /* webpackChunkName: "component---src-landing-page-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-login-jsx": () => import("./../../../src/pages/account/login.jsx" /* webpackChunkName: "component---src-pages-account-login-jsx" */),
  "component---src-pages-account-register-jsx": () => import("./../../../src/pages/account/register.jsx" /* webpackChunkName: "component---src-pages-account-register-jsx" */),
  "component---src-pages-collections-good-light-products-jsx": () => import("./../../../src/pages/collections/good-light-products.jsx" /* webpackChunkName: "component---src-pages-collections-good-light-products-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pages-about-jsx": () => import("./../../../src/pages/pages/about.jsx" /* webpackChunkName: "component---src-pages-pages-about-jsx" */),
  "component---src-pages-pages-faq-jsx": () => import("./../../../src/pages/pages/faq.jsx" /* webpackChunkName: "component---src-pages-pages-faq-jsx" */),
  "component---src-pages-pages-privacy-policy-jsx": () => import("./../../../src/pages/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-pages-privacy-policy-jsx" */),
  "component---src-pages-pages-terms-and-condition-jsx": () => import("./../../../src/pages/pages/terms-and-condition.jsx" /* webpackChunkName: "component---src-pages-pages-terms-and-condition-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-product-page-index-jsx": () => import("./../../../src/ProductPage/index.jsx" /* webpackChunkName: "component---src-product-page-index-jsx" */)
}

