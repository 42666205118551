import { ShopifyData } from "./Query";

// ============================================================================================== //
// ====================================== CREATE NEW CUSTOMER =================================== //
// ============================================================================================== //

export const createCustomer = async (customer) => {
  const query = `mutation {
    customerCreate(input: {acceptsMarketing: true, firstName: "${customer.firstName}", lastName: "${customer.lastName}", email: "${customer.email}", password: "${customer.password}"}) {
        customer {
        id
        displayName
        email
        orders(first: 100) {
          nodes {
            name
            currentSubtotalPrice {
              amount
            }
            currentTotalPrice {
              amount
            }
            currentTotalTax {
              amount
            }
            lineItems {
              nodes {
                title
                quantity
                variant {
                  image {
                    url
                    altText
                  }
                }
              }
            }
          }
        }
      }
    customerUserErrors {
      code
      field
      message
    }
    }
  }
  `;

  const response = await ShopifyData(query);
  return response.data.customerCreate;
};

export const createAccessToken = async (customer) => {
  const query = `mutation {
    customerAccessTokenCreate(input: {email: "${customer.email}", password: "${customer.password}"}){
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;
  const response = await ShopifyData(query);
  return response.data.customerAccessTokenCreate;
};

export const resetPassword = async (email) => {
  const query = `mutation {
    customerRecover(email: "${email}") {
     customerUserErrors {
      field
      code
      message
     }
   }
 }`;
  const response = await ShopifyData(query);
  return response.data.customerRecover?.customerUserErrors;
};

export const addCustomerAddress = async (accessToken, customerDetails) => {
  const query = `mutation {
    customerAddressCreate(customerAccessToken: "${accessToken}", address: {firstName: "${customerDetails.firstName}", lastName: "${customerDetails.lastName}", address1: "${customerDetails.addressLine1}", address2: "${customerDetails.addressLine2}", country: "${customerDetails.country}", city: "${customerDetails.city}", phone: "${customerDetails.phone}", province: "${customerDetails.state}", zip: "${customerDetails.zip}"}) {
      customerAddress {
        firstName
        lastName
        address1
        address2
        country
        city
        id
        zip
      }
    }
  }  
  `;
  const response = await ShopifyData(query);
  return response.data.customerAddressCreate.customerAddress;
};

export const getCustomerInfo = async (accessToken) => {
  const query = `query {
    customer(customerAccessToken: "${accessToken}") {
      id
      defaultAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        country
        province
        zip
        phone
      }
      addresses(first: 100) {
        nodes {
          id
          firstName
          lastName
          address1
          address2
          city
          country
          province
          zip
          phone
        }
      }
    }
  }
  
  `;
  const response = await ShopifyData(query);
  return response.data.customer;
};

export const getOrderDetails = async (accessToken) => {
  const query = `query {
    customer(customerAccessToken: "${accessToken}") {
      id
      orders(first: 10) {
        nodes {
          name
        successfulFulfillments {
          trackingInfo {
            number
            url
          }
        }
        statusUrl
        shippingAddress {
          address1
          address2
          city
          province
          country
          countryCodeV2
          
        }
          orderNumber
          processedAt
          cancelReason
          canceledAt
          currentSubtotalPrice {
            amount
          }
          currentTotalPrice {
            amount
          }
          currentTotalTax {
            amount
          }
          totalShippingPriceV2 {
            amount
          }
          customerUrl
          fulfillmentStatus
          lineItems(first: 100) {
            nodes {
              title
              quantity
              discountAllocations {
                discountApplication {
                  allocationMethod
                  targetSelection
                  targetType
                }
              }
              variant {
                sellingPlanAllocations(first: 10)  {
                  nodes {
                    sellingPlan {
                      name
                      options {
                        name
                        value
                      }
                    }
                  }
                }
                image {
                  url
                  altText
                }
                priceV2 {
                  amount
                  currencyCode
                }
                title
                
              }
              originalTotalPrice {
                amount
              }
              discountAllocations {
                allocatedAmount {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  }`;
  const response = await ShopifyData(query);

  return response.data.customer;
};

export const deleteCustomerAddress = async (accessToken, addressId) => {
  const query = `mutation {
    customerAddressDelete(customerAccessToken: "${accessToken}", id: "${addressId}") {
      deletedCustomerAddressId
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;
  const response = await ShopifyData(query);
  return response.data.customerAddressDelete;
};

export const updateCustomerAddress = async (
  accessToken,
  addressId,
  customerDetails
) => {
  const query = `mutation {
    customerAddressUpdate(customerAccessToken: "${accessToken}", id: "${addressId}", address: {firstName: "${customerDetails.firstName}", lastName: "${customerDetails.lastName}", address1: "${customerDetails.addressLine1}", address2: "${customerDetails.addressLine2}", country: "${customerDetails.country}", city: "${customerDetails.city}", phone: "${customerDetails.phone}", province: "${customerDetails.state}", zip: "${customerDetails.zip}"}) {
      customerAddress {
        firstName
        lastName
        address1
        address2
        country
        city
        id
        zip
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;
  const response = await ShopifyData(query);
  return response.data.customerAddressUpdate;
};

export const defaultCustomerAddress = async (accessToken, addressId) => {
  const query = `mutation {
    customerDefaultAddressUpdate(customerAccessToken: "${accessToken}", addressId: "${addressId}") {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;
  const response = await ShopifyData(query);
  return response.data.customerDefaultAddressUpdate;
};
